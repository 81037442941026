<template>
  <div class="content">
    <back-button />

    <div class="info-text">Edit connections:</div>

    <div v-if="$store.getters.is2P">
      <unit-enable-group-2P
        ref="group1"
        v-model="connection0"
        :connection="1"
        :enabled="getEnabled(0)"
        :enable-changed="(v) => enableChanged(0, v)"
        class="phase-group"
        hint
      />
      <unit-enable-group-2P
        ref="group2"
        v-model="connection1"
        :connection="3"
        :enabled="getEnabled(1)"
        :enable-changed="(v) => enableChanged(1, v)"
        class="phase-group"
        hint
      />
      <unit-enable-group-2P
        ref="group3"
        v-model="connection2"
        :connection="5"
        :enabled="getEnabled(2)"
        :enable-changed="(v) => enableChanged(2, v)"
        class="phase-group"
        hint
      />
      <unit-enable-group-2P
        ref="group4"
        v-model="connection3"
        :connection="7"
        :enabled="getEnabled(3)"
        :enable-changed="(v) => enableChanged(3, v)"
        class="phase-group"
        hint
      />
      <unit-enable-group-2P
        ref="group5"
        v-model="connection4"
        :connection="9"
        :enabled="getEnabled(4)"
        :enable-changed="(v) => enableChanged(4, v)"
        class="phase-group"
        hint
      />
    </div>

    <div v-else>
      <unit-enable-group-3P
        ref="group1"
        v-model="connection0"
        :connection="1"
        :enabled="getEnabled(0)"
        :enable-changed="(v) => enableChanged(0, v)"
        class="phase-group"
        hint
      />
      <unit-enable-group-3P
        ref="group2"
        v-model="connection1"
        :connection="2"
        :enabled="getEnabled(1)"
        :enable-changed="(v) => enableChanged(1, v)"
        class="phase-group"
        hint
      />
      <unit-enable-group-3P
        ref="group3"
        v-model="connection2"
        :connection="3"
        :enabled="getEnabled(2)"
        :enable-changed="(v) => enableChanged(2, v)"
        class="phase-group"
        hint
      />
      <unit-enable-group-3P
        ref="group4"
        v-model="connection3"
        :connection="4"
        :enabled="getEnabled(3)"
        :enable-changed="(v) => enableChanged(3, v)"
        class="phase-group"
        hint
      />
      <unit-enable-group-3P
        ref="group5"
        v-model="connection4"
        :connection="5"
        :enabled="getEnabled(4)"
        :enable-changed="(v) => enableChanged(4, v)"
        class="phase-group"
        hint
      />
    </div>

    <div v-if="!isValidEnabledArray" class="error-message">
      There must be at least one enabled unit on each phase
    </div>
    <div>
      <a-row
        v-if="$store.getters.isCommissioner"
        type="flex"
        align="middle"
        class="re-commission"
      >
        <white-button
          bordered
          to="/reset-connections"
          data-tn="re-connect-a-unit"
        >
          ADD A CONNECTION
        </white-button>
      </a-row>
      <a-row type="flex" align="middle" class="back-button">
        <a-col :span="12">
          <div
            class="back-button"
            @click="
              () => {
                this.$store.commit('resetConnectionFromSystemInfo');
                this.$router.go(-1);
              }
            "
          >
            <a-icon type="left" /> BACK
          </div>
        </a-col>
        <a-col :span="12">
          <yellow-button :loading="loading" @click="saveClicked">
            SAVE
          </yellow-button>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { enabledUnitsMixin } from "../mixins/enabledUnits";

export default {
  mixins: [enabledUnitsMixin],
  data() {
    return {
      loading: false,
      valid: true,
      enabled: JSON.parse(
        JSON.stringify(
          this.$store.state.systemInfo.config.enabled_units || [[], [], []]
        )
      ),
    };
  },
  computed: {
    isValidEnabledArray() {
      return this.enabled.every((arr) => arr.length > 0);
    },
    connection0: {
      get() {
        return this.$store.state.project.connections[0];
      },
      set(val) {
        this.$store.commit("setConnection", { index: 0, connection: val });
      },
    },
    connection1: {
      get() {
        return this.$store.state.project.connections[1];
      },
      set(val) {
        this.$store.commit("setConnection", { index: 1, connection: val });
      },
    },
    connection2: {
      get() {
        return this.$store.state.project.connections[2];
      },
      set(val) {
        this.$store.commit("setConnection", { index: 2, connection: val });
      },
    },
    connection3: {
      get() {
        return this.$store.state.project.connections[3];
      },
      set(val) {
        this.$store.commit("setConnection", { index: 3, connection: val });
      },
    },
    connection4: {
      get() {
        return this.$store.state.project.connections[4];
      },
      set(val) {
        this.$store.commit("setConnection", { index: 4, connection: val });
      },
    },
  },
  methods: {
    validate(valid) {
      if (!valid) {
        this.valid = false;
        return valid;
      }
    },
    enableChanged(index, v) {
      v.forEach((value, i) => {
        if (value === true && !this.enabled[i].includes(index)) {
          this.enabled[i].push(index);
          this.enabled[i].sort();
        }
        if (value === false && this.enabled[i].includes(index)) {
          this.$set(
            this.enabled,
            i,
            this.enabled[i].filter((v) => v !== index)
          );
        }
      });
    },
    confirmClicked() {
      this.$refs.group1.validate(this.validate);
      this.$refs.group2.validate(this.validate);
      this.$refs.group3.validate(this.validate);
      this.$refs.group4.validate(this.validate);
      this.$refs.group5.validate(this.validate);
      if (this.valid) {
        this.$router.push("/commission/17");
      } else {
        this.valid = true;
      }
    },
    async saveClicked() {
      this.$refs.group1.validate(this.validate);
      this.$refs.group2.validate(this.validate);
      this.$refs.group3.validate(this.validate);
      this.$refs.group4.validate(this.validate);
      this.$refs.group5.validate(this.validate);
      if (this.valid && this.isValidEnabledArray) {
        this.loading = true;
        try {
          await this.$http.post(
            this.$api.updateConnectedUnits(
              this.$store.state.project.serialNumber
            ),
            {
              connections: this.$store.state.project.connections,
              enabledUnits:
                JSON.stringify(
                  this.$store.state.systemInfo.config.enabled_units
                ) === JSON.stringify(this.enabled)
                  ? null
                  : this.enabled,
              oldConfig: this.$store.state.systemInfo.originalConfig,
            }
          );

          await this.$store.dispatch("getSystemInfo", {
            serial: this.$store.state.project.serialNumber,
            redirect: false,
          });
          this.$router.go(-1);
        } catch (e) {
          await this.$store.dispatch("getSystemInfo", {
            serial: this.$store.state.project.serialNumber,
            redirect: false,
          });
        }

        this.loading = false;
      } else {
        this.valid = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .info-text {
    margin-top: 29px;
    margin-bottom: 43px;
  }

  .phase-group {
    margin-bottom: 35px;
  }

  .small-text {
    margin-bottom: 11px;
    width: 195px;
  }

  .error-message {
    color: red;
  }

  .re-commission {
    margin-bottom: 20px;
  }

  .back-button {
    font-size: 15px;
    cursor: pointer;

    span {
      letter-spacing: 2px;
    }
  }
}
</style>
