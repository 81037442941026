import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

import Login from "../views/Login.vue";
import GoodDay from "../views/GoodDay.vue";
import CreateAccount from "../views/CreateAccount.vue";
import ResetPassword from "../views/ResetPassword.vue";
import Welcome from "../views/Welcome.vue";
import InfoCentre from "../views/InfoCentre.vue";
import Feedback from "../views/Feedback.vue";
import WifiStatus from "../views/WifiStatus.vue";
import FeedbackSubmitted from "../views/FeedbackSuccess.vue";
import Contact from "../views/Contact.vue";
import SafetyLabel from "../views/SafetyLabel.vue";
import PowerOnSolshare from "../views/PowerOnSolshare.vue";
import HowToEnterSerialNumber from "../views/SerialNumberHow.vue";
import SerialNumberManually from "../views/SerialNumberManually.vue";
import SerialNumberQRCode from "../views/SerialNumberQRCode.vue";
import SearchingSolshare from "../views/SearchingSolshare.vue";
import SearchingSolshareFailedOffline from "../views/SearchingSolshareFailedOffline.vue";
import SearchingSolshareNotFound from "../views/SearchingSolshareFailedNotFound.vue";
import SearchingSolshareFailedNeedPowerCycle from "../views/SearchingSolshareFailedNeedPowerCycle.vue";
import SearchingSolshareAlready from "../views/SearchingSolshareAlready.vue";
import SearchingSolshareSuccess from "../views/SearchingSolshareSuccess.vue";
import AddASite from "../views/AddASite.vue";
import SystemSpecifications from "../views/SystemSpecifications.vue";
import SolshareConnectedToInverter from "../views/SolshareConnectedToInverter.vue";
import PhaseTypeChoose from "../views/PhaseTypeChoose.vue";
import PhaseTypeSingle from "../views/PhaseTypeSingle.vue";
import PhaseTypeTriple from "../views/PhaseTypeTriple.vue";
import PhaseTypeReview from "../views/PhaseTypeReview.vue";
import PhaseTypeReviewEdit from "../views/PhaseTypeReviewEdit.vue";
import SolarDeliveryMethod from "../views/SolarDeliveryMethod.vue";
import SolarDeliveryMethodUnsure from "../views/SolarDeliveryMethodUnsure.vue";
import CTRating from "../views/CTRating.vue";
import CTRatingUnsure from "../views/CTRatingUnsure.vue";
import CommissionSolshare from "../views/CommissionSolshare.vue";
import CommissionSolshareInProgress from "../views/CommissionSolshareInProgress.vue";
import CommissionSolshareFailed from "../views/CommissionSolshareFailed.vue";
import CommissionSolshareSuccess from "../views/CommissionSolshareSuccess.vue";
import Congratulations from "../views/Congratulations.vue";
import Error from "../views/Error.vue";
import CreateAccountConfirmed from "../views/CreateAccountConfirmd";
import UnequalAllocation from "../views/UnequalAllocation";
import UnequalAllocationReview from "../views/UnequalAllocationReview";
import NetPromoterScore from "../views/NetPromoterScore";
import SystemInfo from "../views/SystemInfo";
import SolsharesAtSite from "../views/SolsharesAtSite";
import SystemBuildingAddressEdit from "../views/SystemBuildingAddressEdit";
import FaultStatus from "../views/FaultStatus";
import SolarDeliveryMethodEdit from "../views/SolarDeliveryMethodEdit";
import SystemBuildingAddressView from "../views/SystemBuildingAddressView";
import SolarDeliveryMethodView from "../views/SolarDeliveryMethodView";
import PhaseTypeReviewEditServiceInterface from "../views/PhaseTypeReviewEditServiceInterface";
import PhaseTypeReviewServiceInterface from "../views/PhaseTypeReviewServiceInterface";
import SoftwareUpdate from "../views/SoftwareUpdate";
import SoftwareUpdateSuccess from "../views/SoftwareUpdateSuccess";
import SoftwareUpdateFailed from "../views/SoftwareUpdateFailed";
import ViewFleet from "../views/ViewFleet";
import AccessControl from "../views/AccessControl";
import MySites from "../views/MySites.vue";
import SoftwareUpdateInstruction from "../views/SoftwareUpdateInstruction.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: GoodDay,
  },
  {
    path: "/feedback/1",
    component: Feedback,
    meta: { requiresAuth: true, showWifiIcon: true },
  },
  {
    path: "/feedback/2",
    component: FeedbackSubmitted,
    meta: { requiresAuth: true, showWifiIcon: true },
  },
  {
    path: "/contact",
    component: Contact,
    meta: { showWifiIcon: true },
  },
  {
    path: "/wifi-status",
    component: WifiStatus,
    meta: { requiresAuth: true, showWifiIcon: true },
  },
  {
    path: "/login",
    component: Login,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next("/welcome");
      } else {
        next();
      }
    },
  },
  {
    path: "/create-account",
    component: CreateAccount,
  },
  {
    path: "/account-created",
    component: CreateAccountConfirmed,
  },
  {
    path: "/reset-password/:token",
    component: ResetPassword,
  },
  {
    path: "/welcome",
    component: Welcome,
    meta: { requiresAuth: true },
  },
  {
    path: "/info-centre",
    component: InfoCentre,
  },
  {
    path: "/safety-label",
    component: SafetyLabel,
    meta: { requiresAuth: true },
  },
  {
    path: "/commission/1",
    component: PowerOnSolshare,
    meta: { requiresAuth: true },
  },
  {
    path: "/my-sites",
    component: MySites,
    meta: { requiresAuth: true },
  },
  {
    path: "/add-a-site",
    component: AddASite,
    meta: { requiresAuth: true },
  },
  {
    path: "/commission/2",
    component: HowToEnterSerialNumber,
    meta: { requiresAuth: true },
  },
  {
    path: "/wifi-enter-serial",
    component: SerialNumberManually,
    props: { nextAction: "wifiStatus" },
    meta: { requiresAuth: true },
  },
  {
    path: "/reconfigure-enter-serial",
    component: SerialNumberManually,
    props: { nextAction: "getSystemInfo" },
    meta: { requiresAuth: true },
  },
  {
    path: "/commission/3",
    component: SerialNumberManually,
    props: { nextAction: "searchMeter" },
    meta: { requiresAuth: true },
  },
  {
    path: "/commission/4",
    component: SerialNumberQRCode,
    meta: { requiresAuth: true },
  },
  {
    path: "/commission/5",
    component: SearchingSolshare,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (from.path === "/error") {
        next("/commission/2");
      } else {
        next();
      }
    },
  },
  {
    path: "/commission/6/1",
    component: SearchingSolshareFailedOffline,
    meta: { requiresAuth: true, showWifiIcon: true },
  },
  {
    path: "/commission/6/2",
    component: SearchingSolshareNotFound,
    meta: { requiresAuth: true },
  },
  {
    path: "/commission/6/3",
    component: SearchingSolshareFailedNeedPowerCycle,
    meta: { requiresAuth: true },
  },
  {
    path: "/commission/7",
    component: SearchingSolshareAlready,
    meta: { requiresAuth: true },
  },
  {
    path: "/commission/8",
    component: SearchingSolshareSuccess,
    meta: { requiresAuth: true, showWifiIcon: true },
  },
  {
    path: "/software-update-instruction",
    component: SoftwareUpdateInstruction,
    meta: { requiresAuth: true, showWifiIcon: true },
  },
  {
    path: "/software-update",
    component: SoftwareUpdate,
    meta: { requiresAuth: true, showWifiIcon: true },
  },
  {
    path: "/software-update-success",
    component: SoftwareUpdateSuccess,
    meta: { requiresAuth: true, showWifiIcon: true },
  },
  {
    path: "/software-update-failed",
    component: SoftwareUpdateFailed,
    meta: { requiresAuth: true, showWifiIcon: true },
  },
  {
    path: "/commission/10",
    component: SystemSpecifications,
    meta: { requiresAuth: true, showWifiIcon: true },
  },
  {
    path: "/commission/11",
    component: SolshareConnectedToInverter,
    meta: { requiresAuth: true, showWifiIcon: true },
  },
  {
    path: "/commission/12/:index",
    component: PhaseTypeChoose,
    meta: { requiresAuth: true, showWifiIcon: true },
  },
  {
    path: "/commission/13/:index",
    component: PhaseTypeTriple,
    meta: { requiresAuth: true, showWifiIcon: true },
  },
  {
    path: "/commission/14/:index",
    component: PhaseTypeSingle,
    meta: { requiresAuth: true, showWifiIcon: true },
  },
  {
    path: "/commission/15",
    component: PhaseTypeReview,
    meta: { requiresAuth: true, showWifiIcon: true },
  },
  {
    path: "/view-connected-units",
    component: PhaseTypeReviewServiceInterface,
    meta: { requiresAuth: true, showWifiIcon: true },
  },
  {
    path: "/commission/16",
    component: PhaseTypeReviewEdit,
    meta: { requiresAuth: true, showWifiIcon: true },
  },
  {
    path: "/edit-connected-units",
    component: PhaseTypeReviewEditServiceInterface,
    meta: { requiresAuth: true, showWifiIcon: true },
  },
  {
    path: "/commission/17",
    component: SolarDeliveryMethod,
    meta: { requiresAuth: true, showWifiIcon: true },
  },
  {
    path: "/commission/18",
    component: SolarDeliveryMethodUnsure,
    meta: { requiresAuth: true, showWifiIcon: true },
  },
  {
    path: "/commission/ct-rating",
    component: CTRating,
    meta: { requiresAuth: true, showWifiIcon: true },
  },
  {
    path: "/commission/ct-rating-unsure",
    component: CTRatingUnsure,
    meta: { requiresAuth: true, showWifiIcon: true },
  },
  {
    path: "/commission/19",
    component: CommissionSolshare,
    meta: { requiresAuth: true, showWifiIcon: true },
  },
  {
    path: "/commission/20",
    component: CommissionSolshareFailed,
    meta: { requiresAuth: true },
  },
  {
    path: "/commission/21",
    component: CommissionSolshareInProgress,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (from.path === "/error") {
        next("/commission/19");
      } else {
        next();
      }
    },
  },
  {
    path: "/net-promoter-score",
    component: NetPromoterScore,
    meta: { requiresAuth: true },
  },
  {
    path: "/commission/22",
    component: CommissionSolshareSuccess,
    meta: { requiresAuth: true },
  },
  {
    path: "/commission/unequal-allocation/:index",
    component: UnequalAllocation,
    meta: { requiresAuth: true, showWifiIcon: true },
  },
  {
    path: "/commission/unequal-allocation-review",
    component: UnequalAllocationReview,
    meta: { requiresAuth: true, showWifiIcon: true },
  },
  {
    path: "/congratulations",
    component: Congratulations,
    meta: { requiresAuth: true },
  },
  {
    path: "/system-info",
    component: SystemInfo,
    meta: { requiresAuth: true },
  },
  {
    path: "/solshares-at-site",
    component: SolsharesAtSite,
    meta: { requiresAuth: true },
  },
  {
    path: "/view-building-address",
    component: SystemBuildingAddressView,
    meta: { requiresAuth: true, showWifiIcon: true },
  },
  {
    path: "/edit-building-address",
    component: SystemBuildingAddressEdit,
    meta: { requiresAuth: true, showWifiIcon: true },
  },
  {
    path: "/fault-status",
    component: FaultStatus,
    meta: { requiresAuth: true, showWifiIcon: true },
  },
  {
    path: "/view-solar-deliver-method",
    component: SolarDeliveryMethodView,
    meta: { requiresAuth: true, showWifiIcon: true },
  },
  {
    path: "/edit-solar-deliver-method",
    component: SolarDeliveryMethodEdit,
    meta: { requiresAuth: true, showWifiIcon: true },
  },
  {
    path: "/view-fleet",
    component: ViewFleet,
    meta: { requiresAuth: true },
  },
  {
    path: "/access-control",
    component: AccessControl,
    meta: { requiresAuth: true },
  },
  {
    path: "/reset-connections",
    component: PhaseTypeReviewEdit,
    meta: { requiresAuth: true },
  },
  {
    path: "/error",
    component: Error,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  // login guard
  const shouldLogin =
    to.matched.some((record) => record.meta.requiresAuth) &&
    !store.getters.isLoggedIn;

  if (shouldLogin) {
    return next({ path: "/login" });
  }

  // Wi-Fi polling guard
  const shouldShowIcon =
    to.matched.some((record) => record.meta.showWifiIcon) &&
    store.state.project.serialNumber !== "";

  if (shouldShowIcon) {
    if (!store.state.showWifiIcon) {
      store.commit("setWifiIcon", true);
    }
    // after page refresh, will need to set timer again
    if (store.state.wifiIntervalID === null) {
      store.dispatch("startPollingWifiConnection");
    }
  } else {
    if (store.state.showWifiIcon) {
      store.commit("setWifiIcon", false);
    }
    if (store.state.wifiIntervalID !== null) {
      store.dispatch("stopPollingWifiConnection");
    }
  }
  next();
});

router.onError((err) => console.log(err));

export default router;
